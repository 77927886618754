import React, { useState, useEffect, useContext } from "react"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { m } from "framer-motion"
import { ChevronUpIcon } from "@heroicons/react/solid"
import { PageContext } from "../context/context"
import Header from "./header"
import { graphql, useStaticQuery } from "gatsby"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const Footer = loadable(() => import("./footer"))
const ProductsDynamic = loadable(() => import("./goods/ProductsDynamic"))
const BreadCrumb = loadable(() => import("./elements/BreadCrumb"), {
  resolveComponent: components => components.BreadCrumb,
})
const Modal = loadable(() => import("./layout/modal"), {
  resolveComponent: components => components.Modal,
})

const Layout = ({ children, location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query CatMenu {
      cats: allCategory(filter: { menu: { eq: "main" }, parentId: { eq: 1 } }) {
        edges {
          node {
            id: catId
            name
            url
            menu
            parentId
            parent {
              id
            }
            children {
              ... on Category {
                id: catId
                name
                url
                lft
                menu
                parentId
                children {
                  ... on Category {
                    id: catId
                    name
                    url
                    lft
                    menu
                    parentId
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [showGoTop, setGoTop] = useState(false)
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
  }

  const handleScroll = () => {
    if (window.scrollY >= 300) {
      setGoTop(true)
    } else {
      setGoTop(false)
    }
  }

  useEffect(() => {
    window.onscroll = () => handleScroll()
  }, []) // IMPORTANT, This will cause react to update depending on change of this value

  const [context] = useContext(PageContext)
  const searchString = context?.searchString

  return (
    <>
      {/* <CookieConsent
        location="none"
        buttonText={
          <div className="rounded-md bg-gray-100 px-6 py-2  hover:bg-gray-300 text-black font-bold text-lg">
            Souhlasím
          </div>
        }
        buttonWrapperClasses="w-full grid gap-4 lg:gap-2 "
        declineButtonText={
          <div className="rounded-md  px-6 py-2 hover:bg-gray-100  hover:bg-opacity-30 text-white w-[70%] mx-auto opacity-70 ">
            Nesouhlasím
          </div>
        }
        enableDeclineButton
        flipButtons={true}
        disableStyles
        overlay={true}
        contentClasses="lg:col-span-2"
        overlayClasses=" bg-black bg-opacity-40  z-[1000] h-[300px]  inset-0 w-full h-full fixed flex justify-center  "
        cookieName="gatsby-gdpr-google-tagmanager"
        containerClasses="fixed transition bottom-0 md:bottom-auto md:top-[40%]   bg-gradient-to-br grid lg:grid-cols-3 gap-8 from-indigo-600 to-indigo-950  z-[1000] w-auto  lg:w-[700px]  border border-gray-400 p-8 md:rounded-md shadow-lg  lg:my-10  "
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        <div className="text-xl font-bold   text-white mb-3  ">
          Používáme cookies
        </div>
        <div className=" text-xs text-gray-100">
          Základní cookies potřebujeme k zajištění správného fungování stránek
        </div>
      </CookieConsent> */}

      <div className="relative bg-white md:min-h-[800px]">
        <Header location={location} data={data} pageContext={pageContext} />
        <main className="pt-20 pb-1 ">
          <div
            className={"h-10 " + (location.pathname === "/" ? "hidden" : "")}
          >
            <BreadCrumb pageContext={pageContext} data={data} />
          </div>

          {searchString ? (
            <>
              <ProductsDynamic location={location} pageContext={pageContext} />
            </>
          ) : (
            children
          )}
        </main>
      </div>

      <Footer />

      <m.div
        className="bg-indigo-950 hover:bg-indigo-700 border fixed bottom-6 right-6 rounded-full z-50   "
        style={{
          transform: showGoTop ? "none" : "translateY(40px)",
          opacity: showGoTop ? 1 : 0,
          transition: "all 0.3s  0.3s",
        }}
      >
        <button
          type={"button"}
          onClick={handleScrollUp}
          className="p-3"
          aria-label="Nahoru"
        >
          <ChevronUpIcon className="w-6 h-6 text-white" />
        </button>
      </m.div>
      <Modal location={location} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
