import React from "react"

function flattenCategories(categories) {
  const flattened = []
  const categoriesWithoutNode = categories.map(category => category.node)

  const flatten = category => {
    flattened.push(category)

    if (category.children && category.children.length > 0) {
      category.children.forEach(child => {
        flatten(child) // Rekurzivní volání pro každé dítě
      })
    }
  }

  categoriesWithoutNode.forEach(category => {
    flatten(category)
  })

  return flattened
}

export default function categoryHierarchy(categories, categoryId) {
  let allCategories = []
  if (categories && categories.length > 0) {
    allCategories = flattenCategories(categories)
  }

  const getCategoryHierarchy = categoryId => {
    const hierarchy = []
    const categoryMap = {}

    allCategories.forEach(category => {
      categoryMap[category.id] = category
    })

    let currentCategoryId = categoryId

    while (currentCategoryId !== null) {
      const category = categoryMap[currentCategoryId]
      if (category) {
        hierarchy.unshift(category)
        currentCategoryId = category.parentId
      } else {
        currentCategoryId = null
      }
    }

    return hierarchy
  }

  const categoryHierarchy = getCategoryHierarchy(categoryId)

  return categoryHierarchy
}
