import { InformationCircleIcon } from "@heroicons/react/solid"
import React from "react"

export const Error = ({ text }) => {
  return (
    <div className="rounded-md bg-sky-50 mt-1 p-2 flex space-x-2 border-sky-200 border">
      <InformationCircleIcon className="w-5 h-5 text-sky-600" />
      <span
        className="text-sm text-sky-700"
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    </div>
  )
}
