import { navigate } from "gatsby-link"
import React, { useState, useEffect, useCallback, useContext } from "react"
import { useForm } from "react-hook-form"
import { PageContext } from "../../context/context"

export const GoodsSearch = ({ location }) => {
  const { register, handleSubmit, getValues, setValue } = useForm()
  const [context, dispatch] = useContext(PageContext)
  const [searchString, setSearchString] = useState()

  useEffect(() => {
    let url
    let urlParams
    if (location) {
      url = new URL(location?.href)
      urlParams = new URLSearchParams(url?.search)
      setSearchString(urlParams.get("s"))
    }

    // eslint-disable-next-line
  }, [location])

  const onSubmit = async data => {
    if (data.search) {
      navigate("/vyhledavani/?s=" + data.search)
    }
  }

  useEffect(() => {
    dispatch({
      type: "SET_SEARCHSTRING",
      payload: {
        searchString: searchString,
      },
    })

    // eslint-disable-next-line
  }, [searchString])

  useEffect(() => {
    //location.pathName !== "/vyhledavani/" && setValue("search")
    // eslint-disable-next-line
  }, [location])

  const debounce = (func, debounceTimeout) => {
    let timeout
    return function executedFunction(...args) {
      const delayedFunction = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(delayedFunction, debounceTimeout)
    }
  }

  // eslint-disable-next-line
  const debouncedSubmit = useCallback(
    debounce(name => {
      setSearchString(getValues("search"))
    }, 700),
    []
  )

  function search() {
    debouncedSubmit()
  }

  return (
    <>
      <div className=" h-full w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="search" className="sr-only">
            Vyhledávání
          </label>
          <div className="relative w-full px-1  mx-auto">
            <input
              autoComplete="off"
              {...register("search")}
              defaultValue={context?.searchString}
              onInput={() => search()}
              name="search"
              className="block w-full lg:min-w-44 bg-white  border lg:border-gray-400 rounded-full py-2 pl-5 pr-10   placeholder-gray-800 placeholder-opacity-50 lg:bg-white hover:bg-white lg:border lg:shadow-sm  focus:outline-none focus:text-gray-900 focus:bg-white focus:placeholder-gray-200 focus:ring-1  focus:ring-indigo-900 focus:border-indigo-900   sm:text-sm"
              placeholder="Hledat"
              type="search"
            />
            <button
              type="submit"
              className=" absolute  inset-y-0 right-0  p-3 flex items-center text-gray-800 hover:text-indigo-700 opacity-50 hover:opacity-100"
              aria-label="Vyhledat"
            >
              <svg
                className="h-5 w-5  "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="pointer-events-none absolute inset-y-0 right-14 pr-5 flex items-center">
              {/* {loading && 
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            } */}
            </div>
          </div>
        </form>

        {/* {loading && <Spin /> } */}
      </div>
    </>
  )
}

export default GoodsSearch
