import React, { useState, useEffect, useRef, useContext } from "react"
import { useForm } from "react-hook-form"
import { ModalLayout } from "../layout/modal"
import axiosInstance from "../../client/index.js"
import { Error } from "../form/Error"
import { ChevronRightIcon } from "@heroicons/react/outline"

import Steps from "./Steps"
import { PageContext } from "../../context/context"

const steps = [
  { name: "Košík", href: "#", current: false },
  { name: "Kontaktní údaje", href: "#", current: true },
  { name: "Rekapitulace", href: "#", current: false },
]

const First = props => {
  const [action] = useState(JSON.parse(localStorage.getItem("userDataStatus")))

  const [resMessage, setResponse] = useState()
  const setActiveTab = props.setActiveTab
  const setLoading = props.setLoading
  const setUserData = props.setUserData

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  })

  useEffect(
    () => {
      if (action?.data?.email) {
        setValue("email", action.data.email)
      }
    },
    // eslint-disable-next-line
    [action]
  )

  const onSubmit = async data => {
    setUserData({
      email: data.email,
    })
    setLoading(true)
    //console.log(data)
    try {
      const res = await axiosInstance.post("users_data/sendPin/", {
        email: data.email,
      }) // Fetch the resource
      if (res.data) {
        setActiveTab(2)
      }
      setLoading(false)
      // Do your JSON handling here
    } catch (error) {
      setResponse(error?.response?.data?.message)
      setTimeout(() => {
        setResponse(false)
      }, 10000)
      setLoading(false)
      // This probably means your response is text, do you text handling here
    }
    //setItems(result.items)
    //setCart(result.cart)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-1xl">
          <div className="w-full lg:p-8 lg:pt-0 ">
            <>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Váš e-mail
                </label>

                {!action?.data ? (
                  <input
                    id="email"
                    type="text"
                    {...register("email", { required: true })}
                    className={
                      " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none  "
                    }
                  />
                ) : (
                  <input
                    id="email"
                    type="text"
                    {...register("email", { required: true })}
                    disabled={true}
                    className={
                      " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none bg-gray-200"
                    }
                  />
                )}

                {errors?.login && <Error text={"Prosím vyplňte heslo"} />}
              </div>
              <p className="text-sm py-2">
                Na tento e-mail Vám zašleme PIN, který v dalším kroku opište do
                formuláře.
              </p>
              {resMessage && (
                <div className="rounded-md bg-red-50 mt-2 p-2">
                  <svg
                    className="h-5 w-5 text-red-400 inline mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm text-red-700">{resMessage}</span>
                </div>
              )}
            </>
            <div className="mt-3 flex justify-end">
              <button className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                <span>Poslat PIN</span>
                <ChevronRightIcon className="w-8 " />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const Second = props => {
  const userData = props.userData
  const [resMessage, setResponse] = useState()
  const setActiveTab = props.setActiveTab
  const setLoading = props.setLoading

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  })

  const onSubmit = async data => {
    setLoading(true)
    //console.log(data)
    try {
      const res = await axiosInstance.post("users_data/checkPin/", {
        email: userData?.email,
        pin: data.pin,
      }) // Fetch the resource
      if (res.data) {
        setActiveTab(3)
      }
      setLoading(false)
      // Do your JSON handling here
    } catch (error) {
      setResponse(error?.response?.data?.message)
      setTimeout(() => {
        setResponse(false)
      }, 10000)
      setLoading(false)
      // This probably means your response is text, do you text handling here
    }
    //setItems(result.items)
    //setCart(result.cart)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-1xl">
          <div className="w-full lg:p-8 lg:pt-0 ">
            <>
              <div className="mt-4">
                <label
                  htmlFor="pin"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  PIN
                </label>

                <input
                  id="pin"
                  type="text"
                  {...register("pin", { required: true })}
                  className={
                    " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none  "
                  }
                />

                {errors?.pin && <Error text={"Prosím vložte PIN"} />}
              </div>
              <p className="text-sm py-2">
                Přepište PIN z e-mailu. Pokud PIN nedorazil, zkontrolujte složku
                s nevyžádanou poštou SPAM.
              </p>
              {resMessage && (
                <div className="rounded-md bg-red-50 mt-2 p-2">
                  <svg
                    className="h-5 w-5 text-red-400 inline mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm text-red-700">{resMessage}</span>
                </div>
              )}
            </>
            <div className="mt-3 flex justify-end">
              <button className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                <span>Pokračovat</span>
                <ChevronRightIcon className="w-8 " />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const Third = props => {
  const userData = props.userData
  const [resMessage, setResponse] = useState()
  const setLoading = props.setLoading

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const [authContext, dispatch] = useContext(PageContext)

  const onSubmit = async data => {
    setLoading(true)
    //console.log(data)
    try {
      const res = await axiosInstance.post("users_data/updatePassword/", {
        password: data.password,
        email: userData.email,
      }) // Fetch the resource
      if (res.data) {
        dispatch({
          type: "CLEAR_MODAL",
        })
        authContext.login(res.data)
      }
      // Do your JSON handling here
    } catch (error) {
      console.log(error)
      setResponse(error?.response?.data?.message)
      setTimeout(() => {
        setResponse(false)
      }, 10000)
      setLoading(false)
      // This probably means your response is text, do you text handling here
    }
    //setItems(result.items)
    //setCart(result.cart)
  }

  const password = useRef({})
  password.current = watch("password", "")

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-1xl">
          <div className="w-full lg:p-8 lg:pt-0 ">
            <>
              <div className="mt-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Vaše nové heslo
                </label>

                <input
                  type="password"
                  id="password"
                  {...register("password", { required: true })}
                  className={
                    " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none  "
                  }
                />
                {errors?.pass && <Error text={"Prosím vyplňte heslo"} />}
              </div>
              <div className="mt-4">
                <label
                  htmlFor="password2"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Vaše nové heslo znovu
                </label>

                <input
                  type="password"
                  id="password2"
                  {...register("pass2", {
                    required: true,
                    validate: value =>
                      value === password.current || "Hesla bohužel nesouhlasí",
                  })}
                  className={
                    " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none  "
                  }
                />

                {errors?.pass2 && (
                  <Error text={"Prosím opište heslo " + errors.pass2.message} />
                )}
              </div>
              <p className="text-sm py-2">
                Téměř hotovo! Teď už si jen nastavte nové heslo.
              </p>
              {resMessage && (
                <div className="rounded-md bg-red-50 mt-2 p-2">
                  <svg
                    className="h-5 w-5 text-red-400 inline mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm text-red-700">{resMessage}</span>
                </div>
              )}
            </>
            <div className="mt-3 flex justify-end">
              <button className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                <span>Dokončit</span>
                <ChevronRightIcon className="w-8 " />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export const PassPin = props => {
  const [userData, setUserData] = useState(props.userData)
  const [activeTab, setActiveTab] = useState(1)
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  return (
    <>
      <ModalLayout title={"Nastavení hesla"}>
        <Steps
          steps={steps}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setUserData={setUserData}
          tabs={true}
        />
        {activeTab === 1 && (
          <First
            userData={userData}
            setLoading={setLoading}
            setUserData={setUserData}
            setActiveTab={setActiveTab}
          />
        )}
        {activeTab === 2 && (
          <Second
            userData={userData}
            setLoading={setLoading}
            setUserData={setUserData}
            setActiveTab={setActiveTab}
          />
        )}
        {activeTab === 3 && (
          <Third
            {...props}
            userData={userData}
            setLoading={setLoading}
            setUserData={setUserData}
            setActiveTab={setActiveTab}
          />
        )}
      </ModalLayout>
    </>
  )
}
