export const SET_ITEMS = "SET_ITEMS"
export const SET_MENU = "SET_MENU"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT"

const setItems = (data, state) => {
  return { ...state, cart: data }
}

const addProductToCart = (product, state) => {
  //console.log(state)
  let cart = { ...state.cart }

  return { ...state, cart: cart }
}

const removeProductFromCart = (productId, state) => {
  console.log("remove product: " + productId)
  const updatedCart = [...state.cart]
  const updatedItemIndex = updatedCart.findIndex(item => item.id === productId)

  const updatedItem = {
    ...updatedCart[updatedItemIndex],
  }
  updatedItem.quantity--
  if (updatedItem.quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1)
  } else {
    updatedCart[updatedItemIndex] = updatedItem
  }

  return { ...state, cart: updatedCart }
}

export const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      const cartHash =
        "cart_" +
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      localStorage.setItem("cartId", cartHash)
      return {
        ...state,
      }
    case "SET_ITEMS":
      return setItems(action.data, state)
    case "ADD_PRODUCT":
      return addProductToCart(action.product, state)
    case "REMOVE_PRODUCT":
      return removeProductFromCart(action.productId, state)
    case "SET_MENU":
      return {
        ...state,
        menu: action.payload.menu,
      }
    case "SET_MODAL":
      return {
        ...state,
        modal: {
          component: action.payload.component,
          params: action.payload.params,
          open: true,
          fullscreen: action.payload.fullscreen,
        },
      }
    case "OPEN_CART":
      return {
        ...state,
        miniCart: {
          open: true,
        },
      }
    case "CLOSE_CART":
      return {
        ...state,
        miniCart: {
          open: false,
        },
      }
    case "SET_STORAGE":
      return {
        ...state,
        storage: action.payload.storage,
      }
    case "SET_CATS":
      return {
        ...state,
        cats: action.payload.cats,
      }
    case "SET_CATEGORY":
      return {
        ...state,
        category: action.payload.category,
      }
    case "SET_USER":
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload.data,
        token: action.payload.token,
      }
    case "CLEAR_USER":
      return {
        ...state,
        isLoggedIn: false,
        userData: null,
      }
    case "SET_BREADCRUMB":
      return {
        ...state,
        breadCrumbPath: {
          path: action.payload.path,
          previous: action.payload.previous,
        },
      }
    case "SET_SEARCHSTRING":
      return {
        ...state,
        searchString:
          action?.payload?.searchString && action.payload.searchString.trim(),
      }
    case "CLEAR_MODAL":
      return {
        ...state,
        modal: {
          component: null,
          params: null,
          open: false,
        },
      }
    default:
      return state
  }
}
