import React, { Fragment, useContext, useEffect, useState } from "react"
import { Transition, Popover, Dialog } from "@headlessui/react"
import MainMenuItem from "./MainMenuItem"
import { default as Logo } from "../../images/storm.svg"
import { MenuIcon } from "@heroicons/react/outline"
import { Script } from "gatsby"
import categoryHierarchy from "../../utils/category-hierarchy"
import { PageContext } from "../../context/context"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

const MainMenu = ({ open, white, setOpen, location, data, pageContext }) => {
  let categories = data?.cats?.edges

  const [visible, setVisible] = useState(false)
  const [path, setPath] = useState([])
  const [context] = useContext(PageContext)

  useEffect(() => {
    setVisible(false)
    //eslint-disable-next-line
  }, [location])

  useEffect(() => {
    let catPath =
      context?.breadCrumbPath?.path?.length > 0
        ? context.breadCrumbPath.path
        : categoryHierarchy(categories, pageContext?.catData?.id)
    const urlArray = Object.values(catPath).map(category => category.url)

    setPath(urlArray)
  }, [context])

  const kontakt = {
    node: {
      id: 7,
      parentId: 1,
      menu: "main",
      name: "Kontakt",
      url: "kontakty",
      children: [],
    },
  }

  const menu = data?.cats?.edges || []

  useEffect(() => {
    menu.push(kontakt)
  }, [])

  return (
    <>
      <nav className="hidden xl:flex ml-24 2xl:ml-48 tracking-tight h-full   font-bold">
        {menu &&
          menu.length > 0 &&
          menu.map((page, i) => (
            <MainMenuItem
              item={page.node}
              key={i}
              white={white}
              path={path}
              mobileClose={() =>
                function () {
                  return false
                }
              }
            />
          ))}
      </nav>

      <div className="flex items-center xl:hidden">
        {/* Mobile menu button */}
        <button
          onClick={() => setVisible(true)}
          className="xl:hidden xl:w-0 mx-2 p-2 inline-flex items-center justify-center   hover:bg-gray-100 focus:outline-none "
        >
          <span className="sr-only">Otevřít menu</span>
          <MenuIcon
            className={
              "block h-8 w-8 " + (white ? "text-white" : "text-gray-500")
            }
            aria-hidden="true"
          />
        </button>
      </div>
      <div className=" xl:hidden  ">
        <Transition.Root show={visible} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 overflow-hidden  z-50 "
            onClose={() => setVisible(false)}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 xl:hidden backdrop-filter backdrop-blur-sm" />
              </Transition.Child>

              <div className="fixed xl:hidden inset-y-0 right-0   max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className=" w-80 h-screen shadow-lg  bg-white  ">
                    <div className="bg-white">
                      <div className="flex items-center justify-between border-b  p-4 pl-10">
                        <div>
                          <img src={Logo} className="h-8  " alt="Logo" />
                        </div>
                        <div className="-mr-2">
                          <button
                            onClick={() => setVisible(false)}
                            type="button"
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none "
                          >
                            <span className="sr-only">Close menu</span>
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className=" h-full  ">
                      <nav className="  overflow-y-auto   p-5">
                        {menu.map((page, i) => (
                          <MainMenuItem
                            item={page.node}
                            key={i}
                            setOpen={setOpen}
                            path={path}
                            mobile={true}
                            visible={visible}
                            setVisible={setVisible}
                          />
                        ))}
                      </nav>

                      <div className="p-10  block   border-t text-gray-600 mt-5">
                        <div className="grid gap-4 xl:flex xl:space-x-8">
                          <a
                            href="tel:+420774696622"
                            className="flex space-x-2 font-semibold"
                          >
                            <PhoneIcon className="w-6 h-6" />
                            <span>+420 774 696 622</span>
                          </a>
                          <div className="flex space-x-2 font-semibold">
                            <MailIcon className="w-6 h-6" />
                            <a href="mailto:info@stormwatches.cz">
                              info@stormwatches.cz
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-center mt-7">
                            <div id="showHeurekaBadgeHere-3"></div>
                          </div>
                          <Script id="showHeurekaBadgeHere-3">{`var _hwq = _hwq || [];
    _hwq.push(['setKey', '160C566ECA728A8F62E9C439DBBE3C81']);_hwq.push(['showWidget', '3', '28951', 'STORM', 'stormwatches-cz']);(function() {
    var ho = document.createElement('script'); ho.type = 'text/javascript'; ho.async = true;
    ho.src = 'https://cz.im9.cz/direct/i/gjs.php?n=wdgt&sak=160C566ECA728A8F62E9C439DBBE3C81';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);
})();`}</Script>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  )
}

export default MainMenu
