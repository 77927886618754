import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MainMenuItem = ({ item, setVisible, parent, catId, path }) => {
  let children = item.children.filter(item => item.menu !== "")
  if (children.length === 0) {
    children = false
  }

  const [forceOpen, setForceOpen] = useState(
    path && path.length > 0 && path.includes(item.url) ? true : false
  )

  const mainButtonClass =
    " group rounded-md text-lg xl:text-base flex justify-between w-full xl:w-auto xl:inline-flex items-center font-medium hover:text-white focus:outline-none p-4 py-3 xl:p-2 xl:px-5 xl:mx-1 max-w-[80%] xl:max-w-full mx-auto  hover:bg-indigo-950 "
  return (
    <>
      {!children ? (
        <>
          <div className=" ">
            <div className={" flex   "}>
              <Link
                to={"/" + item.url + "/"}
                partiallyActive={item.url === "" ? false : true}
                className={
                  mainButtonClass +
                  (path && path.length > 0 && path.includes(item.url)
                    ? "bg-indigo-950 text-white"
                    : "")
                }
                activeClassName="bg-indigo-950 text-white"
              >
                <span className="truncate">{item.name}</span>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className="xl:relative  text-sm">
          <Popover>
            {({ open, close }) => (
              <>
                <div className=" flex xl:relative ">
                  <Popover.Button
                    className={classNames(
                      parent === parseInt(item.id) ||
                        catId === parseInt(item.id) ||
                        (path && path.length > 0 && path.includes(item.url))
                        ? "bg-indigo-950 text-white"
                        : open
                        ? " bg-indigo-950 text-white "
                        : "text-gray-800  ",
                      mainButtonClass
                    )}
                  >
                    <span>{item.name}</span>
                    <ChevronDownIcon
                      className={classNames(
                        "ml-1 h-5 w-5 group-hover:text-white hidden xl:block opacity-50"
                      )}
                      aria-hidden="true"
                    />
                    <ChevronRightIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-1 h-5 w-5 group-hover:text-white block xl:hidden"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                <Transition
                  show={forceOpen ? forceOpen : open}
                  enter=" duration-150 ease-out "
                  enterFrom="opacity-0 "
                  enterTo="opacity-100 "
                  leave=" duration-150 ease-in "
                  leaveFrom="opacity-100 "
                  leaveTo="opacity-0 "
                >
                  <div
                    className="  fixed w-full  top-0 h-full inset-0 bg-black bg-opacity-25 xl:hidden"
                    aria-hidden="true"
                    onClick={() => {
                      close()
                      setForceOpen(false)
                    }}
                  ></div>
                </Transition>

                <Transition
                  show={forceOpen ? forceOpen : open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-x-full xl:translate-x-0 xl:scale-95"
                  enterTo="opacity-100 translate-x-0  xl:scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-x-0 xl:scale-100"
                  leaveTo="opacity-0 translate-x-full xl:translate-x-0 xl:scale-95"
                >
                  <Popover.Panel
                    static
                    className="absolute h-screen xl:h-auto   top-0 xl:top-10 right-0 left-16 xl:left-0 xl:absolute z-50 inset-x-0 transition transform xl:shadow-lg  xl:w-80"
                  >
                    {/* podmenu */}
                    <div className="h-full ">
                      <nav className="h-full bg-white  border pb-10 xl:rounded-md px-3">
                        <div className="w-full py-5 xl:hidden ">
                          <button
                            className="px-3 w-full"
                            onClick={() => {
                              close()
                              setForceOpen(false)
                            }}
                          >
                            <ChevronLeftIcon className="w-10 h-10 text-gray-500" />
                          </button>
                        </div>
                        <Link
                          to={"/" + item.url + "/"}
                          partiallyActive={item.url === "" ? false : true}
                          className={
                            "p-3 mt-2 text-xl flex items-center rounded-md  font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 w-full "
                          }
                          activeClassName="  hover:text-indigo-800   "
                        >
                          {item.name}
                        </Link>
                        <div>
                          <ul className="my-3 ">
                            {children.map((page, i) => (
                              <li key={i} className="flow-root">
                                <Link
                                  to={"/" + page.url + "/"}
                                  partiallyActive={
                                    page.url === "" ? false : true
                                  }
                                  className={
                                    "p-3 flex items-center rounded-md    hover:bg-gray-100 transition ease-in-out duration-150 w-full " +
                                    (path &&
                                    path.length > 0 &&
                                    path.includes(page.url)
                                      ? "text-indigo-700 font-bold "
                                      : "text-gray-900  font-normal")
                                  }
                                  activeClassName=" text-indigo-700 hover:text-indigo-800 border-blue-600  "
                                >
                                  {/* <page.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" /> */}
                                  <span className="ml-4">{page.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      )}
    </>
  )
}

export default MainMenuItem
