import React, { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import axiosInstance from "../../client"
import { PassPin } from "../wizard/PassPin"

import { PageContext } from "../../context/context"

import { LockClosedIcon, MailIcon } from "@heroicons/react/solid"

export const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = useState(false)
  const [resMessage, setResponse] = useState()
  const [action] = useState(JSON.parse(localStorage.getItem("userDataStatus")))
  const [authContext, dispatch] = useContext(PageContext)

  const onSubmit = async data => {
    setLoading(true)
    try {
      const res = await axiosInstance.post("?e=apiauth", {
        login: data.login,
        pass: data.pass,
      })
      if (res.data) {
        localStorage.setItem(
          "token",
          JSON.stringify({
            ...res.data,
            expiration: new Date(new Date().getTime() + 60 * 60 * 1000),
          })
        )
        authContext.login(res.data)
        dispatch({
          type: "CLEAR_MODAL",
        })
      }
    } catch (error) {
      setResponse(error?.response?.data?.message)
      setTimeout(() => {
        setResponse(false)
      }, 10000)
    }
    setLoading(false)
  }

  function setPass() {
    dispatch({
      type: "SET_MODAL",
      payload: {
        component: PassPin,
        params: {},
      },
    })
  }

  useEffect(
    () => {
      if (authContext.isLoggedIn) {
        return false
      }
    },
    // eslint-disable-next-line
    [authContext]
  )

  useEffect(
    () => {
      if (action?.data?.email) {
        setValue("login", action.data.email)
      }
    },
    // eslint-disable-next-line
    [action]
  )

  return (
    <>
      <div className={"   flex-col justify-center  "}>
        <div className="  bg-left-bottom bg-opacity-20 h-full ">
          <div className="flex w-full lg:items-center lg:h-full  sm:p-10  ">
            <div className="flex bg-white  rounded-lg w-full overflow-hidden mx-auto max-w-sm lg:max-w-[1xl] ">
              <div className="w-full p-8  ">
                <div className="text-center font-semibold text-lg">
                  Přihlášení do Klubu STORM
                </div>
                <div className="text-sm text-neutral-500  mb-16 text-center">
                  Vyplňte své přihlašovací údaje.
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <div className="mt-4">
                      <label
                        htmlFor="login"
                        className="block text-gray-700 text-sm font-semibold mb-2"
                      >
                        E-mail
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MailIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="login"
                          type="text"
                          {...register("login", { required: true })}
                          className=" text-gray-700 focus:outline-none focus:shadow-outline focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded py-2 px-4 block w-full appearance-none pl-10"
                        />
                      </div>
                      {errors.login && (
                        <div className="rounded-md bg-red-50 mt-1 p-2">
                          <svg
                            className="h-5 w-5 text-red-400 inline mr-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="text-sm text-red-700">
                            Vyplňte přihlašovací jméno
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <label
                          htmlFor="password"
                          className="block text-gray-700 text-sm font-semibold "
                        >
                          Heslo
                        </label>
                        <button
                          type="button"
                          id="password"
                          onClick={() => setPass()}
                          tabIndex="-1"
                          className=" text-sm text-gray-500  "
                        >
                          Neznáte heslo?
                        </button>
                      </div>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <LockClosedIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="password"
                          {...register("pass", { required: true })}
                          className="  text-gray-700 focus:outline-none focus:shadow-outline focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded py-2 px-4 pl-10 block w-full appearance-none"
                        />
                      </div>
                      {errors.pass && (
                        <div className="rounded-md bg-red-50 mt-1 p-2">
                          <svg
                            className="h-5 w-5 text-red-400 inline mr-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="text-sm text-red-700">
                            Vyplňte heslo
                          </span>
                        </div>
                      )}
                    </div>
                  </>

                  {resMessage && (
                    <div className="rounded-md bg-red-50 mt-2 p-2">
                      <svg
                        className="h-5 w-5 text-red-400 inline mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-sm text-red-700">{resMessage}</span>
                    </div>
                  )}
                  <div className="mt-8">
                    {loading ? (
                      <button
                        disabled
                        className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                      >
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Přihlašování...</span>
                      </button>
                    ) : (
                      <button className="bg-indigo-950 text-white font-semibold py-2 px-4 w-full rounded hover:bg-indigo-800">
                        Přihlásit
                      </button>
                    )}
                  </div>
                </form>
                <div className="mt-4 flex items-center justify-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
