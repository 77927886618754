import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { PageContext } from "../../context/context"
import axiosInstance from "../../client"
import { navigate } from "gatsby"
import { ShoppingBagIcon } from "@heroicons/react/outline"

export const AddToCart = props => {
  const [context, dispatch] = useContext(PageContext)
  const [loading, setLoading] = useState(false)

  function addTo(product) {
    setLoading(true)
    dispatch({
      type: "ADD_PRODUCT",
      product: product,
    })
    dispatch({
      type: "OPEN_CART",
    })

    axiosInstance
      .post("cart/item_add/" + context?.cart?.cart?.cartHash + "/", {
        itemId: product.id,
        type: "goods",
        price: product.priceVat,
      })
      .then(res => {
        dispatch({ type: "SET_ITEMS", data: res.data })
        setLoading(false)
        navigate("/vlozeno/")
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <>
      {loading ? (
        <div className="flex space-x-2 w-full justify-center items-center bg-indigo-950 h-12 px-4 py-2 text-gray-200    rounded-full border border-gray-500  focus:outline-none ">
          <svg
            className="animate-spin w-6 h-6 inline  "
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <>
          {props.item.available === "-2" && props.item.quantity === 0 ? (
            <>
              <span className="flex space-x-2 w-full justify-center items-center h-12 px-4 py-2 text-gray-600  opacity-50 rounded-full border border-gray-500  focus:outline-none ">
                <span className="truncate">Vyprodáno</span>
              </span>
            </>
          ) : (
            <button
              onClick={() => addTo(props.item)}
              className="flex space-x-2 w-full justify-center items-center h-12 px-4 py-2 text-gray-100 bg-black rounded-full hover:bg-gradient-to-b  hover:text-white hover:from-blue-700 hover:to-blue-900 focus:outline-none shadow-md"
            >
              <svg
                id="Capa_1"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 512 512"
              >
                <g>
                  <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                  <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                </g>
              </svg>
              <span className="truncate">Do košíku</span>
            </button>
          )}
        </>
      )}
    </>
  )
}

export const MiniCart = ({ white }) => {
  const [context, dispatch] = useContext(PageContext)

  const count = context?.cart?.cart?.count

  return (
    <>
      <ul className="flex items-center justify-center ">
        <li className="relative inline-block text-left">
          <div className="flex rounded-md cursor-pointer focus:outline-none  ">
            <Link
              to={"/kosik/"}
              aria-label="Nákupní košík"
              className={
                " cursor-pointer rounded-full p-2 relative  h-10 w-10 justify-center flex items-center  " +
                (count > 0
                  ? "bg-indigo-900 hover:bg-indigo-800 text-white "
                  : " text-gray-500 hover:bg-white hover:text-gray-600")
              }
            >
              <ShoppingBagIcon className={"w-6 h-6   "} />
              {count > 0 && (
                <div className="absolute top-0 -right-1.5 -mt-1.5  text-xs w-5 h-5 bg-red-700 text-white rounded-full flex items-center justify-center border border-white">
                  <span>{count}</span>
                </div>
              )}
            </Link>
          </div>
        </li>
      </ul>
    </>
  )
}
